/**
 * Combined by jsDelivr.
 * Original files:
 * - /npm/jquery@3.4.1/dist/jquery.min.js
 * - /npm/bootstrap@3.4.1/dist/js/bootstrap.min.js
 * - /npm/jquery_lazyload@1.9.3/jquery.lazyload.min.js
 * - /npm/swiper@5.4.5/js/swiper.min.js
 * - /npm/video.js@7.7.6/dist/video.min.js
 * - /npm/cookieconsent@3.0.4/build/cookieconsent.min.js
 *
 * Do NOT use SRI with dynamically generated files! More information: https://www.jsdelivr.com/using-sri-with-dynamic-files
 */
